import { useClientTranslation } from "@rimo/frontend/hooks/i18n/useClientTranslation";
import type { SVGProps } from "react";

export const LinkRound = (props: SVGProps<SVGSVGElement>) => {
  const { customT } = useClientTranslation();
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="16" height="16" rx="8" fill={customT(props.fill)} />
      <g clipPath="url(#clip0_10281_22942)">
        <path
          d="M6.99902 8.49998C7.21375 8.78705 7.4877 9.02457 7.8023 9.19645C8.1169 9.36833 8.46478 9.47054 8.82235 9.49615C9.17992 9.52176 9.53882 9.47017 9.8747 9.34487C10.2106 9.21958 10.5156 9.02352 10.769 8.76998L12.269 7.26998C12.7244 6.79848 12.9764 6.16697 12.9707 5.51148C12.965 4.85599 12.7021 4.22896 12.2386 3.76544C11.775 3.30192 11.148 3.03899 10.4925 3.0333C9.83703 3.0276 9.20553 3.27959 8.73402 3.73498L7.87402 4.58998M8.99912 7.49961C8.7844 7.21254 8.51044 6.97502 8.19585 6.80314C7.88125 6.63126 7.53337 6.52905 7.1758 6.50344C6.81823 6.47783 6.45933 6.52942 6.12345 6.65472C5.78757 6.78001 5.48257 6.97607 5.22912 7.22961L3.72912 8.72961C3.27373 9.20111 3.02174 9.83262 3.02744 10.4881C3.03313 11.1436 3.29606 11.7706 3.75958 12.2342C4.2231 12.6977 4.85013 12.9606 5.50562 12.9663C6.16111 12.972 6.79262 12.72 7.26412 12.2646L8.11912 11.4096"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_10281_22942">
          <rect width="12" height="12" fill={customT(props.fill)} transform="translate(2 2)" />
        </clipPath>
      </defs>
    </svg>
  );
};
